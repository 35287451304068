import api from './api.service'

export const resultService = {
    getAll,
    read,
    openRead,
    resultsForSensor,
    resultsForSensors,
    readSensors,
    getResultTypes,
    getLatestResultsForSensors,
    getLatestAggregatedResultOfTypeForSensor,
    getAggregatedResultsForSensors
};

const BASE_URL = '/result'

function getAll() {
    return api.get(BASE_URL, {max : 1000, sort: 'dateCreated', order: 'desc'})
}

/**
 * Read deviceRef
 */
function read(params) {
    return api.get(BASE_URL + "/read", params)
}

function openRead(params) {
    return api.get(BASE_URL + "/simpleRead", params)
}

function resultsForSensor(sensor) {
    return resultsForSensors([sensor.id])
}

function resultsForSensors(sensorIds) {
    return api.post(BASE_URL + '/resultsForSensors/', {sensors: sensorIds})
}

/*
interval: int,
resolution: int,
sensors: []
*/
function readSensors(params) {
    return api.post(BASE_URL + '/readSensors/', params)
}

function getResultTypes() {
	return api.get(BASE_URL + "/getResultTypes")
}

function getLatestResultsForSensors(sensorIds, type) {
	var sensors = sensorIds.map(sensorId => 'sensor=' + sensorId)
	return api.get(BASE_URL + "/getLatestResultsForSensors?" + sensors + "&type=" + type)
}

function getLatestAggregatedResultOfTypeForSensor(sensorId, type) {
	return api.get(BASE_URL + "/getLatestAggregatedResultOfTypeForSensor/" + sensorId + "?type=" + type)
}

function getAggregatedResultsForSensors(params) {
	return api.post('/aggregatedResult/getAggregatedResultsForSensors/', params)
}