import React, { useEffect, useState } from 'react'
import { resultService } from '../../_services/'
import { useTranslation } from "react-i18next";
import { Line } from 'react-chartjs-2';
import { Row, Col } from "react-bootstrap";
import moment from 'moment'
import 'moment/locale/sv'

function CounterTable({sensor, startDate, endDate, resolution}) {

	const [minData, setMinData] = useState({})
	const [maxData, setMaxData] = useState({})
    const {t} = useTranslation('common');

    useEffect(() => {
        console.log('useEffect')

        moment.locale('sv')

		if (resolution != 'MINUTE') {
	        resultService.getAggregatedResultsForSensors({
	            startDate: startDate,
	            endDate: endDate,
	            resolution: resolution,
	            sensors: [sensor.id],
	            valueCalculation: 'MIN',
	            resultTypes: ['total_counter_1', 'total_counter_2']
	        }).then(data => {
	            console.log(data)

	            setMinData(data)
	        })
        } else {
            setMinData({})
        }
        resultService.getAggregatedResultsForSensors({
            startDate: startDate,
            endDate: endDate,
            resolution: resolution,
            sensors: [sensor.id],
            valueCalculation: 'MAX',
            resultTypes: ['total_counter_1', 'total_counter_2']
        }).then(data => {
            console.log(data)

            setMaxData(data)
        })
    }, [sensor, startDate, endDate, resolution]);

    const getColumnNames = () => {
		var columnNames = []
		switch (resolution) {
			case 'minute':
				var tmpDate = new Date(startDate.getTime())
				while (tmpDate < endDate) {
					columnNames.push(moment(tmpDate).format('YYYY-MM-DD'))
					tmpDate = moment(tmpDate).add(1, 'day').toDate()
				}
				break
			case 'week':
				var tmpDate = new Date(startDate.getTime())
				while (tmpDate < endDate) {
					columnNames.push(moment(tmpDate).format("YYYY [v.]W"))
					tmpDate = moment(tmpDate).add(1, 'week').toDate()
				}
				break
			case 'month':
				var tmpDate = new Date(startDate.getTime())
				while (tmpDate < endDate) {
					columnNames.push(moment(tmpDate).format('YYYY-MMM'))
					tmpDate = moment(tmpDate).add(1, 'month').toDate()
				}
		        break
		}
		return columnNames
    }

	const getColumns = () => {
		var dateRange = []
		var tmpDate = new Date(startDate.getTime())
		while (tmpDate < endDate) {
			dateRange.push(tmpDate)
			switch (resolution) {
				case 'minute':
					tmpDate = moment(tmpDate).add(1, 'day').toDate()
					break
				case 'week':
					tmpDate = moment(tmpDate).add(1, 'week').toDate()
					break
				case 'month':
					tmpDate = moment(tmpDate).add(1, 'month').toDate()
			        break
			}
		}
        return dateRange
    }

    const getCounterDiff = (which, date) => {
        var counterId = 'total_counter_' + which
        if (sensor && minData && minData[counterId] && minData[counterId][sensor.id] && minData[counterId][sensor.id][0]
            && maxData && maxData[counterId] && maxData[counterId][sensor.id] && maxData[counterId][sensor.id][0]) {
            var sensorMinData = minData[counterId][sensor.id]
            var sensorMaxData = maxData[counterId][sensor.id]

            var startTime = null
            var endTime = null
            switch (resolution) {
				case 'minute':
					startTime = moment(date).startOf('day').unix() * 1000
                    endTime = moment(date).endOf('day').unix() * 1000
					break
				case 'week':
					startTime = moment(date).startOf('isoWeek').unix() * 1000
                    endTime = moment(date).endOf('isoWeek').unix() * 1000
					break
				case 'month':
					startTime = moment(date).startOf('month').unix() * 1000
                    endTime = moment(date).endOf('month').unix() * 1000
					break
			}

			switch (resolution) {
				case 'minute':
					var prevValue = 0
		            var total = 0
		            var first = true
		            sensorMaxData.forEach(row => {
		                if (row.x >= startTime && row.x < endTime) {
		                    if (first) {
		                        prevValue = row.y
		                    } else if (row.y < prevValue) {
		                        prevValue = 0
		                    }
		                    total += row.y - prevValue
		                    prevValue = row.y
		                    first = false
		                }
		            })
		            return total
				case 'week':
				case 'month':
					var minValue = null
					var maxValue = null
					sensorMinData.forEach(row => {
		                if (row.x >= startTime && row.x < endTime) {
		                    minValue = row.y
		                }
		            })
					sensorMaxData.forEach(row => {
		                if (row.x >= startTime && row.x < endTime) {
		                    maxValue = row.y
		                }
		            })
		            if (minValue && maxValue) {
		                return maxValue - minValue
		            }
		            break
			}
        }
        return '-'
    }

	return (
		<table className="table" style={{ fontSize: 'large'}}>
			<thead>
				<tr>
					<th></th>
					<th>
						{t('device.columns.name')}
					</th>
					{ getColumnNames().map(columnName =>
						<th key={columnName}>{columnName}</th>
					)}
				</tr>
			</thead>
			<tbody>
				<tr>
					<td style={{width: '25px'}}>
						<svg style={{width: '20px', height: '20px', display: 'inline'}}>
							<rect width="20" height="20" fill="#0000FF" />
						</svg>
					</td>
					<td>Total Counter 1</td>
					{ getColumns().map(date =>
						<td key={moment(date).format('YYYY-MM-DD')}>{getCounterDiff(1, date)}</td>
					)}
				</tr>
				<tr>
					<td style={{width: '25px'}}>
						<svg style={{width: '20px', height: '20px', display: 'inline'}}>
							<rect width="20" height="20" fill="#FF0000" />
						</svg>
					</td>
					<td>Total Counter 2</td>
					{ getColumns().map(date =>
						<td key={moment(date).format('YYYY-MM-DD')}>{getCounterDiff(2, date)}</td>
					)}
				</tr>
			</tbody>
		</table>
	)
}

export default CounterTable;