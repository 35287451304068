import React, { useEffect, useState } from 'react'
import { resultService } from '../../_services/'
import { useTranslation } from "react-i18next";
import { Line } from 'react-chartjs-2';
import { Row, Col } from "react-bootstrap";
import moment from 'moment'
import 'moment/locale/sv'

function TotalCounterChart({sensor, startDate, endDate, resolution}) {

	const [chartData, setChartData] = useState({})
    const {t} = useTranslation('common');

    useEffect(() => {
        console.log('useEffect')

        moment.locale('sv')

        resultService.getAggregatedResultsForSensors({
            startDate: startDate,
            endDate: endDate,
            resolution: resolution,
            sensors: [sensor.id],
            valueCalculation: 'MAX',
            resultTypes: ['total_counter_1', 'total_counter_2']
        }).then(data => {
            console.log(data)

			const datasets = []
	        if (data && data['total_counter_1'] && data['total_counter_1'][sensor.id]) {
		        var data1 = data['total_counter_1'][sensor.id]
		        datasets.push({
		            label: 'Total Counter 1',
		            data: data1,
		            fill: false,
		            borderColor: 'blue',
		            pointBorderColor: 'blue',
		            pointBackgroundColor: 'blue'
		        })
	        }
	        if (data && data['total_counter_2'] && data['total_counter_2'][sensor.id]) {
		        var data2 = data['total_counter_2'][sensor.id]
		        datasets.push({
		            label: 'Total Counter 2',
		            data: data2,
		            fill: false,
		            borderColor: 'red',
		            pointBorderColor: 'red',
		            pointBackgroundColor: 'red'
		        })
	        }
			setChartData({datasets: datasets})
        })
    }, [sensor, startDate, endDate, resolution]);

    const options = {
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                type: 'time',
                time: {
                    isoWeekday: true,
                    tooltipFormat: 'YYYY-MM-DD HH:mm',
                    displayFormats: {
                        millisecond: 'HH:mm:ss.SSS',
                        second: 'HH:mm:ss',
                        minute: 'HH:mm',
                        hour: 'HH:mm',
                    }
                },
                ticks: {
                    major: {
                        enabled: true
                    }
                }
            }],
        }
    }

	return (
		<Line options={options} data={chartData} height={600} />
	)
}

export default TotalCounterChart;